/* Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. */
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
export default {
  props: {
    item: Object,
    value: [String, Object, Array],
  },
  data: () => ({
    data: "",
  }),
  computed: {
    required() {
      return this.item.is_required == 1;
    },
    requiredRule() {
      return (value) => !_isEmpty(value) || this.$t("base.validation.required");
    },
    rules() {
      let rules = [];
      if (this.required) rules.push(this.requiredRule);
      if(this.item.tag === 'email') rules.push(this.emailRule)
      return rules
    },
    isValidEmail() {
      if(!this.data) return true
      return this.$validator.isEmail(this.data)
    },
    emailRule() {
      return value => this.isValidEmail || this.$t('base.validation.email.incorrect')
    },
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.data = _cloneDeep(this.value)
      },
    },
    data: {
      deep: true,
      handler() {
        if(_isEqual(this.data, this.value)) return
        this.sync();
      },
    },
  },
  methods: {
    sync() {
      this.$emit("input", this.data);
    },
  },
};
